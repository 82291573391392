import { useState, SyntheticEvent } from 'react'
// import { Link as RouterLink } from "react-router-dom";

// material-ui
import {
	Button,
	// Checkbox,
	// FormControlLabel,
	FormHelperText,
	Grid,
	// Link,
	InputAdornment,
	InputLabel,
	OutlinedInput,
	Stack,
	Typography,
	// Typography,
} from '@mui/material'

// third-party
import * as Yup from 'yup'
import { Formik } from 'formik'

// project-imports
import useAuth from 'hooks/useAuth'
import useScriptRef from 'hooks/useScriptRef'
import IconButton from 'components/@extended/IconButton'
import AnimateButton from 'components/@extended/AnimateButton'
import Cookies from 'universal-cookie'

// assets
import { Eye, EyeSlash } from 'iconsax-react'
// import { isAdmin, signInFirebase } from "utils/firebase";
import { dispatch } from 'store'
import { isUserAdmin } from 'store/reducers/projects'
import axios from 'axios'
import { setSession } from 'contexts/FirebaseContext'
const JWT_NAME = 'firebase-jwt'
const cookies = new Cookies()

export const getIsAdmin = async (email: string, token: string) => {
	try {
		const user = await axios.get(
			`${process.env.REACT_APP_API_V1_ENDPOINT}/users?email=${email}`,
			{
				headers: {
					Authorization: `Bearer ${token}`,
				},
			}
		)
		return user.data?.[0]
	} catch (err) {
		console.log(err)
	}
}

type SetTokenOptions = {
	expires: Date
	path: string
}

const setToken = (token: string | undefined, options: SetTokenOptions): void =>
	cookies.set(JWT_NAME, token, options)
export const setTokenCookie = (token: string | undefined): void => {
	const expirationDate = new Date()
	// Change 1 for the number of days you want to let this cookie exist
	expirationDate.setDate(expirationDate.getDate() + 1)
	const options = { path: '/', expires: expirationDate }
	setToken(token, options)
}

// ============================|| JWT - LOGIN ||============================ //

const AuthLogin = () => {
	const { firebaseEmailPasswordSignIn } = useAuth()
	const scriptedRef = useScriptRef()

	const [showPassword, setShowPassword] = useState(false)
	const handleClickShowPassword = () => {
		setShowPassword(!showPassword)
	}

	const handleMouseDownPassword = (event: SyntheticEvent) => {
		event.preventDefault()
	}

	return (
		<>
			<Formik
				initialValues={{
					email: 'ilialevin555@gmail.com',
					password: '',
					submit: null,
				}}
				validationSchema={Yup.object().shape({
					email: Yup.string()
						.email('Must be a valid email')
						.max(255)
						.required('Email is required'),
					password: Yup.string().max(255).required('Password is required'),
				})}
				onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
					try {
						// signInFirebase(values.email, values.password);
						const { user } = await firebaseEmailPasswordSignIn(
							values.email,
							values.password
						)

						setTokenCookie((user as any)._delegate.accessToken)
						setSession((user as any)._delegate.accessToken)
						const admin = await getIsAdmin(
							values.email,
							(user as any)._delegate.accessToken
						)
						if (user && admin?.isAdmin) {
							dispatch(isUserAdmin(admin?.isAdmin))

							if (scriptedRef.current) {
								setStatus({ success: true })
								setSubmitting(true)
							}
						} else {
							throw new Error('Permission denied')
						}
					} catch (err: any) {
						console.error(err)
						if (scriptedRef.current) {
							setStatus({ success: false })
							setErrors({ submit: err.message })
							setSubmitting(false)
						}
					}
				}}
			>
				{({
					errors,
					handleBlur,
					handleChange,
					handleSubmit,
					isSubmitting,
					touched,
					values,
				}) => (
					<form noValidate onSubmit={handleSubmit}>
						<Grid container spacing={{ xs: 2, sm: 3 }}>
							<Grid item xs={12}>
								<Stack spacing={1}>
									<InputLabel htmlFor='email-login'>Email Address</InputLabel>
									<OutlinedInput
										id='email-login'
										type='email'
										value={values.email}
										name='email'
										onBlur={handleBlur}
										onChange={handleChange}
										placeholder='Enter email address'
										fullWidth
										error={Boolean(touched.email && errors.email)}
									/>
									{touched.email && errors.email && (
										<FormHelperText
											error
											id='standard-weight-helper-text-email-login'
										>
											{errors.email}
										</FormHelperText>
									)}
								</Stack>
							</Grid>
							<Grid item xs={12}>
								<Stack spacing={1}>
									<InputLabel htmlFor='password-login'>Password</InputLabel>
									<OutlinedInput
										fullWidth
										error={Boolean(touched.password && errors.password)}
										id='-password-login'
										type={showPassword ? 'text' : 'password'}
										value={values.password}
										name='password'
										onBlur={handleBlur}
										onChange={handleChange}
										endAdornment={
											<InputAdornment position='end'>
												<IconButton
													aria-label='toggle password visibility'
													onClick={handleClickShowPassword}
													onMouseDown={handleMouseDownPassword}
													edge='end'
													color='secondary'
												>
													{showPassword ? <Eye /> : <EyeSlash />}
												</IconButton>
											</InputAdornment>
										}
										placeholder='Enter password'
									/>
									{touched.password && errors.password && (
										<FormHelperText
											error
											id='standard-weight-helper-text-password-login'
										>
											{errors.password}
										</FormHelperText>
									)}
								</Stack>
							</Grid>

							<Grid item xs={12} sx={{ mt: -1 }}>
								<Stack
									direction='row'
									justifyContent='space-between'
									alignItems='center'
									spacing={2}
								></Stack>
							</Grid>
							{errors.submit && (
								<Grid item xs={12}>
									<FormHelperText error>{errors.submit}</FormHelperText>
								</Grid>
							)}
							<Grid item xs={12}>
								<Stack spacing={1.5}>
									<AnimateButton>
										<Button
											disableElevation
											disabled={isSubmitting}
											fullWidth
											size='large'
											type='submit'
											variant='contained'
											color='primary'
										>
											Sing in ➜
										</Button>
									</AnimateButton>
									<Stack
										sx={{ textAlign: 'center' }}
										spacing={0.5}
										direction='row'
										alignItems='center'
										justifyContent='center'
									>
										<Typography sx={{ fontWeight: 500 }}>
											Want to become partner?
										</Typography>
										<Button
											href='https://tally.so/r/wLpG2l'
											target='_blank'
											sx={{
												padding: 0,
												minWidth: 'auto',
												minHeight: 'auto',
												fontWeight: 500,
												lineHeight: 1.57,
												color: 'green',
												verticalAlign: 'baseline',
												borderRadius: 0,
												'&:hover': {
													color: 'green',
													textDecoration: 'underline',
													background: 'none',
												},
												'&:after': {
													display: 'none',
												},
											}}
											variant='text'
											disableRipple
										>
											Join us
										</Button>
									</Stack>
								</Stack>
							</Grid>
						</Grid>
					</form>
				)}
			</Formik>
		</>
	)
}

export default AuthLogin
