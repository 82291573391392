// material-ui
import { useTheme } from "@mui/material/styles";

/**
 * if you want to use image instead of <svg> uncomment following.
 *
 * import logoDark from 'assets/images/logo-dark.svg';
 * import logo from 'assets/images/logo.svg';
 *
 */

// ==============================|| LOGO SVG ||============================== //

const LogoMain = ({ reverse, ...others }: { reverse?: boolean }) => {
  const theme = useTheme();

  return (
    /**
     * if you want to use image instead of svg uncomment following, and comment out <svg> element.
     *
     * <img src={theme.palette.mode === 'dark' ? logoDark : logo} alt="icon logo" width="100" />
     *
     */
    <>
      {/* <span
				style={{
					color: theme.palette.mode === 'dark' ? '#fff' : '#000',
					fontWeight: 'bold',
					margin: 0,
					fontSize: '20px',
				}}
			>
				ToonTube ++
			</span> */}
      <svg
        width="184"
        height="43"
        viewBox="0 0 184 43"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M0.734313 33.0583H7.5532L13.6089 19.5686H20.3981L23.4192 12.8379H3.02143L0 19.5686H6.78975L0.734313 33.0583Z"
          fill={theme.palette.mode === "dark" ? "#ffffff" : "#111111"}
        />
        <path
          d="M19.8845 22.9767C19.2102 24.4795 18.8662 25.894 18.8531 27.2234C18.839 28.5521 19.1097 29.7123 19.6656 30.7035C20.2199 31.6962 21.0437 32.4805 22.1342 33.0582C23.2249 33.6356 24.5282 33.9247 26.0436 33.9247C27.5587 33.9247 29.1169 33.6356 30.7162 33.0582C32.316 32.4805 33.8379 31.6962 35.285 30.7035C36.73 29.7123 38.0426 28.5521 39.2223 27.2234C40.4019 25.894 41.3279 24.4794 42.0024 22.9767C42.6765 21.4752 43.0233 20.0549 43.0409 18.7161C43.0586 17.3775 42.7899 16.2132 42.235 15.2211C41.6789 14.2292 40.8613 13.4444 39.7805 12.8662C38.6993 12.2889 37.401 12 35.8859 12C34.3702 12 32.8073 12.2889 31.1985 12.8662C29.5888 13.4444 28.0609 14.2292 26.6156 15.2211C25.1696 16.213 23.8553 17.3775 22.6717 18.7161C21.4879 20.0551 20.5586 21.4752 19.8845 22.9767ZM26.6743 22.9767C26.9423 22.3806 27.3043 21.8213 27.7614 21.3013C28.2184 20.7818 28.7294 20.3245 29.2954 19.9295C29.8616 19.5352 30.4561 19.2265 31.08 19.0054C31.7041 18.7839 32.3079 18.6728 32.891 18.6728C33.4732 18.6728 33.9722 18.7838 34.3873 19.0054C34.8033 19.2265 35.1162 19.5352 35.3277 19.9295C35.5391 20.3247 35.6394 20.7818 35.6302 21.3013C35.6194 21.8214 35.4807 22.3808 35.213 22.9767C34.9449 23.5744 34.582 24.1322 34.1258 24.6525C33.6686 25.1723 33.1597 25.6247 32.5976 26.0101C32.0367 26.3951 31.4491 26.6982 30.8345 26.92C30.2203 27.1417 29.6217 27.2522 29.0395 27.2522C28.4563 27.2522 27.9523 27.1417 27.5271 26.92C27.1022 26.6982 26.7818 26.3951 26.5657 26.0101C26.3501 25.6248 26.2474 25.1723 26.2572 24.6525C26.2673 24.1322 26.4063 23.5744 26.6743 22.9767Z"
          fill={theme.palette.mode === "dark" ? "#ffffff" : "#111111"}
        />
        <path
          d="M42.8611 30.7036C43.4157 31.6963 44.238 32.4806 45.3294 33.0584C46.4204 33.6358 47.724 33.9249 49.2388 33.9249C50.7542 33.9249 52.3118 33.6358 53.9117 33.0584C55.5115 32.4806 57.0337 31.6963 58.4802 30.7036C59.9259 29.7125 61.2381 28.5523 62.4175 27.2235C63.5965 25.8942 64.5234 24.4795 65.1979 22.9769C65.872 21.4753 66.2179 20.0551 66.2364 18.7163C66.2544 17.3776 65.9854 16.2133 65.4305 15.2213C64.8747 14.2293 64.0568 13.4446 62.976 12.8664C61.8942 12.2889 60.5965 12 59.0813 12C57.5662 12 56.0033 12.2889 54.3939 12.8662C52.7839 13.4444 51.2568 14.2292 49.8112 15.2211C48.3644 16.213 47.0506 17.3775 45.8673 18.7161C44.6834 20.0551 43.7539 21.4752 43.0797 22.9767C42.4054 24.4795 42.0621 25.894 42.048 27.2234C42.0346 28.5523 42.3046 29.7125 42.8611 30.7036ZM49.8698 22.9767C50.1375 22.3806 50.4998 21.8213 50.9572 21.3013C51.4134 20.7818 51.9249 20.3245 52.4909 19.9295C53.0564 19.5352 53.6519 19.2265 54.2755 19.0054C54.8996 18.7839 55.5031 18.6728 56.0856 18.6728C56.6687 18.6728 57.1677 18.7838 57.5828 19.0054C57.9988 19.2265 58.3117 19.5352 58.5229 19.9295C58.7343 20.3247 58.8349 20.7818 58.8251 21.3013C58.8153 21.8214 58.6759 22.3808 58.4082 22.9767C58.1398 23.5744 57.7775 24.1322 57.3204 24.6525C56.8639 25.1723 56.3549 25.6247 55.7934 26.0101C55.2319 26.3951 54.6443 26.6982 54.0297 26.92C53.4155 27.1417 52.8172 27.2522 52.234 27.2522C51.6515 27.2522 51.1478 27.1417 50.7229 26.92C50.2977 26.6982 49.9767 26.3951 49.7614 26.0101C49.5458 25.6248 49.4428 25.1723 49.4532 24.6525C49.4625 24.1322 49.6018 23.5744 49.8698 22.9767Z"
          fill={theme.palette.mode === "dark" ? "#ffffff" : "#111111"}
        />
        <path
          d="M85.275 12.8379L80.9697 22.4281L78.4561 12.8379H71.6669L62.5896 33.0583H69.3789L73.6839 23.4681L76.1976 33.0583H82.9872L92.0647 12.8379H85.275Z"
          fill={theme.palette.mode === "dark" ? "#ffffff" : "#111111"}
        />
        <path
          d="M137.615 12.8379H130.825L125.884 23.844C125.677 24.3062 125.393 24.7535 125.034 25.1873C124.674 25.6208 124.275 26.0011 123.837 26.3282C123.398 26.6559 122.927 26.9157 122.423 27.1075C121.918 27.301 121.405 27.3969 120.88 27.3969C120.375 27.3969 119.956 27.301 119.626 27.1075C119.294 26.9157 119.056 26.6559 118.913 26.3282C118.768 26.0011 118.71 25.6208 118.739 25.1873C118.768 24.7535 118.887 24.3062 119.095 23.844L124.035 12.8379H117.246L112.305 23.844C111.683 25.2303 111.361 26.5401 111.343 27.7725C111.324 29.0053 111.568 30.0741 112.074 30.9793C112.581 31.8841 113.338 32.6011 114.343 33.1313C115.349 33.6605 116.551 33.9251 117.95 33.9251C119.348 33.9251 120.788 33.6607 122.269 33.1313C123.75 32.6011 125.155 31.8841 126.484 30.9793C127.813 30.0741 129.022 29.0055 130.109 27.7725C131.196 26.5402 132.052 25.2303 132.674 23.844L137.615 12.8379Z"
          fill="var(--red-color)"
        />
        <path
          d="M155.841 24.7535C155.834 24.0318 155.682 23.4395 155.384 22.9776C156.096 22.5154 156.767 21.9177 157.398 21.1863C158.028 20.454 158.532 19.6648 158.913 18.8173C159.267 18.028 159.465 17.2724 159.508 16.55C159.551 15.828 159.422 15.1928 159.125 14.6433C158.828 14.0946 158.36 13.6562 157.72 13.3293C157.08 13.0016 156.265 12.8379 155.274 12.8379H139.567L130.489 33.0588H146.196C147.187 33.0588 148.154 32.8946 149.098 32.5678C150.041 32.2401 150.911 31.8066 151.706 31.2681C152.502 30.7283 153.206 30.1023 153.817 29.3902C154.429 28.6776 154.912 27.9269 155.266 27.1373C155.655 26.2698 155.846 25.4761 155.841 24.7535ZM148.587 26.7615C148.431 27.1073 148.173 27.401 147.812 27.6427C147.452 27.8828 147.097 28.0033 146.747 28.0033H139.607L140.735 25.4904H147.875C148.224 25.4904 148.472 25.6103 148.616 25.8511C148.761 26.092 148.751 26.3949 148.587 26.7615ZM151.984 19.1929C151.828 19.5399 151.57 19.8332 151.21 20.0737C150.849 20.3148 150.494 20.435 150.144 20.435H143.005L144.146 17.8931H151.285C151.635 17.8931 151.88 18.0181 152.02 18.2687C152.16 18.5186 152.148 18.8272 151.984 19.1929Z"
          fill="var(--red-color)"
        />
        <path
          d="M154.525 33.0586H174.923L177.192 28.0033H163.583L164.724 25.4615H176.643L178.912 20.4061H166.994L168.122 17.8931H181.731L184 12.8379H163.602L154.525 33.0586Z"
          fill="var(--red-color)"
        />
        <path
          d="M104.473 19.5408H111.235L114.24 12.8379H93.9553L90.95 19.5408H97.7116L91.7014 32.9458H98.463L93.9553 42.9999L108.23 26.2432H101.468L104.473 19.5408Z"
          fill="var(--red-color)"
        />
      </svg>
    </>
  );
};

export default LogoMain;
