// project-imports
import services from "utils/mockAdapter";

// types
import { NavItemType } from "types/menu";

// ==============================|| MENU ITEMS - DASHBOARD  ||============================== //

const posts: NavItemType = {
  id: "posts",
  title: "navigation",
  type: "group",
  icon: "posts",
  children: [
    {
      id: "posts",
      title: "posts",
      type: "collapse",
      icon: "posts",
    },
  ],
};

// ==============================|| DASHBOARD - GET ||============================== //

services.onGet("/api/posts").reply(200, { posts });
