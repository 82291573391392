// third-party
import { FormattedMessage } from 'react-intl'

// assets
import {
	Book1,
	WalletMoney,
	People,
	HomeTrendUp,
	Element3,
	StatusUp,
} from 'iconsax-react'

// type
import { NavItemType } from 'types/menu'

// icons
const icons = {
	page: Book1,
	projects: Element3,
	wallet: WalletMoney,
	campaigns: StatusUp,
	dashboard: HomeTrendUp,
	subscribers: People,
}

// ==============================|| MENU ITEMS - PAGES ||============================== //

const pages: NavItemType = {
	id: 'group-pages',
	title: 'studioName',
	type: 'group',
	icon: icons.page,
	children: [
		{
			id: 'dashboard',
			title: <FormattedMessage id='dashboard' />,
			type: 'item',
			url: '/dashboard/home',
			icon: icons.dashboard,
			breadcrumbs: false,
		},
		{
			id: 'series',
			title: <FormattedMessage id='Series' />,
			type: 'item',
			url: '/series',
			icon: icons.projects,
			breadcrumbs: false,
			children: [
				{
					id: 'series',
					title: <FormattedMessage id='Create' />,
					type: 'item',
					url: '/series/:id',
					icon: icons.projects,
				},
				{
					id: 'series',
					title: <FormattedMessage id='create' />,
					type: 'item',
					url: '/series/create',
					icon: icons.projects,
				},
			],
		},
		{
			id: 'wallet',
			title: <FormattedMessage id='wallet' />,
			type: 'item',
			url: '/wallet',
			icon: icons.wallet,
		},
		{
			id: 'campaigns',
			title: <FormattedMessage id='campaigns' />,
			type: 'item',
			url: '/coupons/create',
			icon: icons.campaigns,
			vip: true,
		},
		{
			id: 'subscribers',
			title: <FormattedMessage id='subscribers' />,
			type: 'item',
			url: '/subscribers',
			icon: icons.subscribers,
			breadcrumbs: false,
		},
	],
}

export default pages
