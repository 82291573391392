import { useEffect, useState } from "react";

// project-imports
import Routes from "routes";
import ThemeCustomization from "themes";

import Loader from "components/Loader";
import Locales from "components/Locales";
import RTLLayout from "components/RTLLayout";
import ScrollTop from "components/ScrollTop";
// import Customization from "components/Customization";
import Snackbar from "components/@extended/Snackbar";
import Notistack from "components/third-party/Notistack";

import { dispatch, useSelector } from "store";
import { fetchDashboard } from "store/reducers/menu";

// auth-provider
import { FirebaseProvider as AuthProvider } from "contexts/FirebaseContext";
// import { AWSCognitoProvider as AuthProvider } from 'contexts/AWSCognitoContext';
// import { JWTProvider as AuthProvider } from "contexts/JWTContext";
import { SnackbarOrigin, SnackbarProvider } from "notistack";
// import { Auth0Provider as AuthProvider } from 'contexts/Auth0Context';

// global-styles
import "./styles/global.css";

// ==============================|| APP - THEME, ROUTER, LOCAL  ||============================== //

const App = () => {
  const MAX_SNACK = 3;
  const AUTO_HIDE_DURATION = 3000;
  const POSITION: SnackbarOrigin = {
    vertical: "bottom",
    horizontal: "left",
  };
  const [loading, setLoading] = useState<boolean>(false);
  const isCouponCreator = useSelector(
    (state) => state.profileProjects.isCouponCreator
  );

  useEffect(() => {
    dispatch(fetchDashboard({ isCouponCreator })).then(() => {
      setLoading(true);
    });
  }, [isCouponCreator]);

  if (!loading) return <Loader />;

  return (
    <SnackbarProvider
      maxSnack={MAX_SNACK}
      autoHideDuration={AUTO_HIDE_DURATION}
      anchorOrigin={POSITION}
    >
      <ThemeCustomization>
        <RTLLayout>
          <Locales>
            <ScrollTop>
              <AuthProvider>
                <>
                  <Notistack>
                    <Routes />
                    <Snackbar />
                  </Notistack>
                </>
              </AuthProvider>
            </ScrollTop>
          </Locales>
        </RTLLayout>
      </ThemeCustomization>
    </SnackbarProvider>
  );
};

export default App;
