// types
import { DefaultConfigProps } from "types/config";

// ==============================|| THEME CONSTANT  ||============================== //

export const twitterColor = "#1DA1F2";
export const facebookColor = "#3b5998";
export const linkedInColor = "#0e76a8";

export const APP_DEFAULT_PATH = "/dashboard/home";
export const HORIZONTAL_MAX_ITEM = 6;
export const DRAWER_WIDTH = 280;
export const MINI_DRAWER_WIDTH = 90;
export const HEADER_HEIGHT = 74;
// ==============================|| THEME CONFIG  ||============================== //

const config: DefaultConfigProps = {
  fontFamily: `Inter var`,
  i18n: "en",
  menuOrientation: "vertical",
  menuCaption: true,
  miniDrawer: false,
  container: false,
  mode: "light",
  presetColor: "default",
  themeDirection: "ltr",
  themeContrast: false,
};

export default config;
