// project-imports
import services from 'utils/mockAdapter'

// types
import { NavItemType } from 'types/menu'

// ==============================|| MENU ITEMS - DASHBOARD  ||============================== //

const dashboard: NavItemType = {
	// id: 'group-dashboard',
	// title: 'navigation',
	// type: 'group',
	// icon: 'navigation',
	// children: [
	// 	{
	// 		id: 'dashboard',
	// 		title: 'dashboard',
	// 		type: 'item',
	// 		url: '/dashboard/home',
	// 		icon: 'dashboard',
	// 		breadcrumbs: false,
	// 	},
	// ],
}

// ==============================|| DASHBOARD - GET ||============================== //

services.onGet('/api/dashboard').reply(200, { dashboard: dashboard })
