import { FC } from 'react'
import { Stack } from '@mui/material'
import { Global, LogoutCurve } from 'iconsax-react'
import useAuth from 'hooks/useAuth'
import { dispatch } from 'store'
import { reset } from 'store/reducers/subscriptions'
import { useNavigate } from 'react-router'
import ControlButton from './button'
import { useSelector } from 'store'

const ControlButtons: FC = () => {
	const { logout, user } = useAuth()
	const navigate = useNavigate()
	const { drawerOpen } = useSelector(state => state.menu)

	const studioUrl = `${process.env.REACT_APP_HOSTNAME}/studio/${user?.authorId}`

	const handleLogout = async () => {
		try {
			await logout()
			dispatch(reset())
			navigate(`/login`, {
				state: {
					from: '',
				},
			})
		} catch (err) {
			console.error(err)
		}
	}

	return (
		<Stack
			spacing={drawerOpen ? 0.5 : 2.5}
			alignItems='center'
			justifyContent='flex-start'
			sx={{ px: 1.25 }}
		>
			<ControlButton
				title='View my Studio'
				href={studioUrl}
				icon={Global}
				externalReference
			/>
			<ControlButton
				title='Sign Out'
				icon={LogoutCurve}
				handleClick={handleLogout}
			/>
		</Stack>
	)
}

export default ControlButtons
