// project-imports
import services from "utils/mockAdapter";

// types
import { NavItemType } from "types/menu";

// ==============================|| MENU ITEMS - DASHBOARD  ||============================== //

const series: NavItemType = {
  id: "group-series",
  title: "navigation",
  type: "group",
  icon: "navigation",
  children: [
    {
      id: "series",
      title: "series",
      type: "collapse",
      icon: "series",
      children: [
        {
          id: "series",
          title: "Series",
          type: "item",
          url: "/series",
          children: [
            {
              id: "create",
              title: "Create",
              type: "item",
              url: "/:id",
            },
            {
              id: "series",
              title: "Series",
              type: "item",
              url: "/create",
            },
          ],
        },
      ],
    },
    // {
    //   id: 'components',
    //   title: 'components',
    //   type: 'item',
    //   url: '/components-overview/buttons',
    //   icon: 'components',
    //   target: true,
    //   chip: {
    //     label: 'New',
    //     color: 'primary',
    //     size: 'small'
    //   }
    // }
  ],
};

// ==============================|| DASHBOARD - GET ||============================== //

services.onGet("/api/projects").reply(200, { projects: series });
