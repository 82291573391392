import { FC } from 'react'
import {
	ListItemButton,
	ListItemIcon,
	ListItemText,
	Stack,
	Typography,
} from '@mui/material'
import { GenericCardProps } from 'types/root'
import { ExportSquare } from 'iconsax-react'
import { useSelector } from 'store'

interface IControlButton {
	title: string
	href?: string
	icon?: GenericCardProps['iconPrimary'] | string
	externalReference?: boolean
	handleClick?: () => void
}

const ControlButton: FC<IControlButton> = ({
	title,
	href,
	icon,
	externalReference,
	handleClick,
}) => {
	const { drawerOpen } = useSelector(state => state.menu)

	const Icon = icon!
	const menuIcon = icon ? <Icon size={22} /> : null

	return (
		<ListItemButton
			href={href ? href : undefined}
			target={externalReference ? '_blank' : undefined}
			component='a'
			sx={{
				display: 'flex',
				alignItems: 'center',
				justifyContent: 'center',
				width: drawerOpen ? '100%' : 46,
				height: drawerOpen ? 'auto' : 46,
				px: { xs: drawerOpen ? 2 : 0 },
				py: { xs: drawerOpen ? 1.5 : 0 },
			}}
			onClick={handleClick ? handleClick : undefined}
		>
			<Stack
				direction='row'
				justifyContent='space-between'
				alignItems='center'
				sx={{ width: drawerOpen ? '100%' : 'auto' }}
			>
				<Stack spacing={1.5} direction='row' component='span'>
					{icon && (
						<ListItemIcon
							sx={{
								minWidth: 22,
								minHeight: 22,
							}}
						>
							{menuIcon}
						</ListItemIcon>
					)}
					{drawerOpen && (
						<ListItemText
							sx={{ m: 0 }}
							primary={<Typography variant='h6'>{title}</Typography>}
						/>
					)}
				</Stack>
				{externalReference && drawerOpen && (
					<ListItemIcon
						sx={{
							minWidth: 12,
							minHeight: 12,
						}}
					>
						<ExportSquare size={12} />
					</ListItemIcon>
				)}
			</Stack>
		</ListItemButton>
	)
}

export default ControlButton
