import { createContext, useEffect, useReducer, ReactElement } from "react";
import axios from "utils/axios";

// third-party
import firebase from "firebase/compat/app";
import "firebase/compat/auth";

// action - state management
import { LOGIN, LOGOUT } from "store/reducers/actions";
import authReducer from "store/reducers/auth";

// project-imports
import Loader from "components/Loader";
import { AuthProps, FirebaseContextType } from "types/auth";
import { getIsAdmin } from "sections/auth/auth-forms/AuthLogin";
import { dispatch as logOutDispatch } from "store";
import { reset } from "store/reducers/subscriptions";
import { DEFAULT_USER_IMAGE } from "utils/constants";
import { isUserAdmin, isCouponCreator } from "store/reducers/projects";

// firebase initialize
if (!firebase.apps.length) {
  firebase.initializeApp({
    apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
    authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
    projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
    storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
    messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
    appId: process.env.REACT_APP_FIREBASE_APP_ID,
    measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID,
  });
}

// const
export const initialState: AuthProps = {
  isLoggedIn: false,
  isInitialized: false,
  user: null,
};

export const setSession = (serviceToken?: string | null) => {
  if (serviceToken) {
    localStorage.setItem("serviceToken", serviceToken);
    axios.defaults.headers.common.Authorization = `Bearer ${serviceToken}`;
  } else {
    localStorage.removeItem("serviceToken");
    delete axios.defaults.headers.common.Authorization;
  }
};
// ==============================|| FIREBASE CONTEXT & PROVIDER ||============================== //

const FirebaseContext = createContext<FirebaseContextType | null>(null);

export const FirebaseProvider = ({ children }: { children: ReactElement }) => {
  const [state, dispatch] = useReducer(authReducer, initialState);

  useEffect(
    () =>
      firebase.auth().onAuthStateChanged(async (user: any) => {
        const admin =
          user &&
          (await getIsAdmin(user.email, (user as any)._delegate.accessToken));

        if (user && admin && admin?.isAdmin) {
          const isAdmin = admin?.isAdmin;
          const isUserCouponCreator =
            admin?.userRole?.includes("COUPON_CREATOR");
          logOutDispatch(isUserAdmin(isAdmin));
          logOutDispatch(isCouponCreator(isUserCouponCreator));
          dispatch({
            type: LOGIN,
            payload: {
              isLoggedIn: true,
              token: (user as any)._delegate.accessToken || undefined,
              user: {
                id: user.uid,
                authorId: admin["_id"],
                email: user.email!,
                name: admin.name || "",
                role: admin.name.includes("Ilia") ? "MOLOT" : "",
                image:
                  `${process.env.REACT_APP_API_IMAGE}/${admin.image}` ||
                  DEFAULT_USER_IMAGE,
              },
            },
          });
        } else {
          logOutDispatch(reset());
          dispatch({
            type: LOGOUT,
          });
        }
      }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [dispatch]
  );

  const firebaseEmailPasswordSignIn = (email: string, password: string) =>
    firebase.auth().signInWithEmailAndPassword(email, password);

  const firebaseGoogleSignIn = () => {
    const provider = new firebase.auth.GoogleAuthProvider();
    return firebase.auth().signInWithPopup(provider);
  };

  const firebaseTwitterSignIn = () => {
    const provider = new firebase.auth.TwitterAuthProvider();
    return firebase.auth().signInWithPopup(provider);
  };

  const firebaseFacebookSignIn = () => {
    const provider = new firebase.auth.FacebookAuthProvider();
    return firebase.auth().signInWithPopup(provider);
  };

  const firebaseRegister = async (email: string, password: string) =>
    firebase.auth().createUserWithEmailAndPassword(email, password);

  const logout = () => firebase.auth().signOut();

  const resetPassword = async (email: string) => {
    await firebase.auth().sendPasswordResetEmail(email);
  };

  const updateProfile = () => {};
  if (state.isInitialized !== undefined && !state.isInitialized) {
    return <Loader />;
  }

  return (
    <FirebaseContext.Provider
      value={{
        ...state,
        firebaseRegister,
        firebaseEmailPasswordSignIn,
        login: () => {},
        firebaseGoogleSignIn,
        firebaseTwitterSignIn,
        firebaseFacebookSignIn,
        logout,
        resetPassword,
        updateProfile,
      }}
    >
      {children}
    </FirebaseContext.Provider>
  );
};

export default FirebaseContext;
