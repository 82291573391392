// project-imports
// import applications from './applications'
// import widget from './widget'
// import formsTables from './forms-tables'
// import chartsMap from './charts-map'
import pages from './pages'
import support from './support'

// types
import { NavItemType } from 'types/menu'

// ==============================|| MENU ITEMS ||============================== //

const menuItems: { items: NavItemType[] } = {
	items: [pages, support],
	// items: [pages, widget, applications, formsTables, chartsMap, support],
}

export default menuItems
