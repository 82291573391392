import { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";

// project-imports
import useAuth from "hooks/useAuth";

// types
import { GuardProps } from "types/auth";

// ==============================|| AUTH GUARD ||============================== //

const AuthGuard = ({ children }: GuardProps) => {
  const { isLoggedIn } = useAuth();
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    if (!isLoggedIn) {
      navigate("login", {
        state: {
          from: location.pathname,
        },
        replace: true,
      });
    }
  }, [isLoggedIn, navigate, location.pathname]);

  return children;
};

export default AuthGuard;
