import { lazy } from 'react'

// project-imports
import MainLayout from 'layout/MainLayout'
import CommonLayout from 'layout/CommonLayout'
import Loadable from 'components/Loadable'
import AuthGuard from 'utils/route-guard/AuthGuard'

// render - subscribers
const SubscribersPage = Loadable(lazy(() => import('pages/subscribers')))
const AddSubscribersPage = Loadable(lazy(() => import('pages/subscribers/add')))

// render - posts
const PostsPage = Loadable(lazy(() => import('pages/posts')))
const PostsPageCreate = Loadable(lazy(() => import('pages/posts/create')))

// render - monetization
const MonetizationPage = Loadable(lazy(() => import('pages/monetization')))

// render - wallet
const Wallet = Loadable(lazy(() => import('pages/wallet')))
const RequestWithdraw = Loadable(
	lazy(() => import('pages/wallet/requestWithdrawPage'))
)

// render - dashboard
const DashboardDefault = Loadable(lazy(() => import('pages/dashboard/home')))

const CreateCoupon = Loadable(lazy(() => import('pages/coupons')))

/* const DashboardAnalytics = Loadable(
  lazy(() => import("pages/dashboard/analytics"))
); */
const Projects = Loadable(lazy(() => import('pages/dashboard/projects/index')))

const Posts = Loadable(
	lazy(() => import('pages/dashboard/projects/item/posts/index'))
)

const ProjectPage = Loadable(
	lazy(() => import('pages/dashboard/projects/projectPage/index'))
)

const EditChapter = Loadable(
	lazy(() => import('pages/dashboard/projects/item/editChapter'))
)

// render - widget
const WidgetStatistics = Loadable(lazy(() => import('pages/widget/statistics')))
const WidgetData = Loadable(lazy(() => import('pages/widget/data')))
const WidgetChart = Loadable(lazy(() => import('pages/widget/chart')))

// render - applications
const AppChat = Loadable(lazy(() => import('pages/apps/chat')))
const AppChatMessangers = Loadable(
	lazy(() => import('pages/apps/chatMessangers'))
)
const AppCalendar = Loadable(lazy(() => import('pages/apps/calendar')))

const AppKanban = Loadable(lazy(() => import('pages/apps/kanban')))
const AppKanbanBacklogs = Loadable(
	lazy(() => import('sections/apps/kanban/Backlogs'))
)
const AppKanbanBoard = Loadable(
	lazy(() => import('sections/apps/kanban/Board'))
)

const AppCustomerList = Loadable(lazy(() => import('pages/apps/customer/list')))
const AppCustomerCard = Loadable(lazy(() => import('pages/apps/customer/card')))
const AppCustomerFeedback = Loadable(
	lazy(() => import('pages/apps/customer/feedback'))
)
const AppCustomerFans = Loadable(lazy(() => import('pages/apps/customer/fans')))

const AppInvoiceCreate = Loadable(
	lazy(() => import('pages/apps/invoice/create'))
)
const AppInvoiceDashboard = Loadable(
	lazy(() => import('pages/apps/invoice/dashboard'))
)
const AppInvoiceList = Loadable(lazy(() => import('pages/apps/invoice/list')))
const AppInvoiceDetails = Loadable(
	lazy(() => import('pages/apps/invoice/details'))
)
const AppInvoiceEdit = Loadable(lazy(() => import('pages/apps/invoice/edit')))

const UserProfile = Loadable(lazy(() => import('pages/apps/profiles/user')))
const UserTabPersonal = Loadable(
	lazy(() => import('sections/apps/profiles/user/TabPersonal'))
)
const UserTabPayment = Loadable(
	lazy(() => import('sections/apps/profiles/user/TabPayment'))
)
const UserTabPassword = Loadable(
	lazy(() => import('sections/apps/profiles/user/TabPassword'))
)
const UserTabSettings = Loadable(
	lazy(() => import('sections/apps/profiles/user/TabSettings'))
)

const AccountProfile = Loadable(
	lazy(() => import('pages/apps/profiles/account'))
)
const AccountTabProfile = Loadable(
	lazy(() => import('sections/apps/profiles/account/TabProfile'))
)
const AccountTabPersonal = Loadable(
	lazy(() => import('sections/apps/profiles/account/TabPersonal'))
)
const AccountTabAccount = Loadable(
	lazy(() => import('sections/apps/profiles/account/TabAccount'))
)
const AccountTabPassword = Loadable(
	lazy(() => import('sections/apps/profiles/account/TabPassword'))
)
const AccountTabRole = Loadable(
	lazy(() => import('sections/apps/profiles/account/TabRole'))
)
const AccountTabSettings = Loadable(
	lazy(() => import('sections/apps/profiles/account/TabSettings'))
)

const AppECommProducts = Loadable(
	lazy(() => import('pages/apps/e-commerce/product'))
)
const AppECommProductDetails = Loadable(
	lazy(() => import('pages/apps/e-commerce/product-details'))
)
const AppECommProductList = Loadable(
	lazy(() => import('pages/apps/e-commerce/products-list'))
)
const AppECommCheckout = Loadable(
	lazy(() => import('pages/apps/e-commerce/checkout'))
)
const AppECommAddProduct = Loadable(
	lazy(() => import('pages/apps/e-commerce/add-product'))
)

// render - forms & tables
const FormsValidation = Loadable(lazy(() => import('pages/forms/validation')))
const FormsWizard = Loadable(lazy(() => import('pages/forms/wizard')))

const FormsLayoutBasic = Loadable(
	lazy(() => import('pages/forms/layouts/basic'))
)
const FormsLayoutMultiColumn = Loadable(
	lazy(() => import('pages/forms/layouts/multi-column'))
)
const FormsLayoutActionBar = Loadable(
	lazy(() => import('pages/forms/layouts/action-bar'))
)
const FormsLayoutStickyBar = Loadable(
	lazy(() => import('pages/forms/layouts/sticky-bar'))
)

const FormsPluginsMask = Loadable(
	lazy(() => import('pages/forms/plugins/mask'))
)
const FormsPluginsClipboard = Loadable(
	lazy(() => import('pages/forms/plugins/clipboard'))
)
const FormsPluginsRecaptcha = Loadable(
	lazy(() => import('pages/forms/plugins/re-captcha'))
)
const FormsPluginsEditor = Loadable(
	lazy(() => import('pages/forms/plugins/editor'))
)
const FormsPluginsDropzone = Loadable(
	lazy(() => import('pages/forms/plugins/dropzone'))
)

const ReactTableBasic = Loadable(
	lazy(() => import('pages/tables/react-table/basic'))
)
const ReactTableSorting = Loadable(
	lazy(() => import('pages/tables/react-table/sorting'))
)
const ReactTableFiltering = Loadable(
	lazy(() => import('pages/tables/react-table/filtering'))
)
const ReactTableGrouping = Loadable(
	lazy(() => import('pages/tables/react-table/grouping'))
)
const ReactTablePagination = Loadable(
	lazy(() => import('pages/tables/react-table/pagination'))
)
const ReactTableRowSelection = Loadable(
	lazy(() => import('pages/tables/react-table/row-selection'))
)
const ReactTableExpanding = Loadable(
	lazy(() => import('pages/tables/react-table/expanding'))
)
const ReactTableEditable = Loadable(
	lazy(() => import('pages/tables/react-table/editable'))
)
const ReactTableDragDrop = Loadable(
	lazy(() => import('pages/tables/react-table/drag-drop'))
)
const ReactTableColumnHiding = Loadable(
	lazy(() => import('pages/tables/react-table/column-hiding'))
)
const ReactTableColumnResizing = Loadable(
	lazy(() => import('pages/tables/react-table/column-resizing'))
)
const ReactTableStickyTable = Loadable(
	lazy(() => import('pages/tables/react-table/sticky'))
)
const ReactTableUmbrella = Loadable(
	lazy(() => import('pages/tables/react-table/umbrella'))
)
const ReactTableEmpty = Loadable(
	lazy(() => import('pages/tables/react-table/empty'))
)

// render - charts & map
const ChartApexchart = Loadable(lazy(() => import('pages/charts/apexchart')))
const ChartOrganization = Loadable(lazy(() => import('pages/charts/org-chart')))

// table routing
const MuiTableBasic = Loadable(
	lazy(() => import('pages/tables/mui-table/basic'))
)
const MuiTableDense = Loadable(
	lazy(() => import('pages/tables/mui-table/dense'))
)
const MuiTableEnhanced = Loadable(
	lazy(() => import('pages/tables/mui-table/enhanced'))
)
const MuiTableDatatable = Loadable(
	lazy(() => import('pages/tables/mui-table/datatable'))
)
const MuiTableCustom = Loadable(
	lazy(() => import('pages/tables/mui-table/custom'))
)
const MuiTableFixedHeader = Loadable(
	lazy(() => import('pages/tables/mui-table/fixed-header'))
)
const MuiTableCollapse = Loadable(
	lazy(() => import('pages/tables/mui-table/collapse'))
)

// pages routing
const AuthLogin = Loadable(lazy(() => import('pages/auth/login')))
const AuthRegister = Loadable(lazy(() => import('pages/auth/register')))
const AuthForgotPassword = Loadable(
	lazy(() => import('pages/auth/forgot-password'))
)
const AuthResetPassword = Loadable(
	lazy(() => import('pages/auth/reset-password'))
)
const AuthCheckMail = Loadable(lazy(() => import('pages/auth/check-mail')))
const AuthCodeVerification = Loadable(
	lazy(() => import('pages/auth/code-verification'))
)

const MaintenanceError = Loadable(lazy(() => import('pages/maintenance/404')))
const MaintenanceError500 = Loadable(
	lazy(() => import('pages/maintenance/500'))
)
const MaintenanceUnderConstruction = Loadable(
	lazy(() => import('pages/maintenance/under-construction'))
)
const MaintenanceComingSoon = Loadable(
	lazy(() => import('pages/maintenance/coming-soon'))
)

// render - sample page
const SamplePage = Loadable(lazy(() => import('pages/extra-pages/sample-page')))
const PricingPage = Loadable(lazy(() => import('pages/extra-pages/pricing')))

// feedback - sample page
// const FeedbackDefault = Loadable(lazy(() => import('pages/feedback/default')));

// ==============================|| MAIN ROUTES ||============================== //

const MainRoutes = {
	path: '/',
	children: [
		{
			path: '/',
			element: (
				<AuthGuard>
					<MainLayout />
				</AuthGuard>
			),
			children: [
				{
					path: '/subscribers',
					element: <SubscribersPage />,
				},
				{
					path: '/subscribers/add',
					element: <AddSubscribersPage />,
				},
				{
					path: '/wallet',
					element: <Wallet />,
				},
				{
					path: '/wallet/request-withdraw',
					element: <RequestWithdraw />,
				},
				{
					path: '/series',
					element: <Projects />,
				},
				{
					path: '/series/chapter/edit/:projectId/:id',
					element: <EditChapter />,
				},
				{
					path: '/series/:id',
					element: <ProjectPage />,
				},
				{
					path: '/series/create',
					element: <ProjectPage />,
				},
				{
					path: '/posts/:id',
					element: <PostsPage />,
				},
				{
					path: '/posts/create/:id',
					element: <PostsPageCreate />,
				},
				{
					path: '/monetization/:id',
					element: <MonetizationPage />,
				},
				{
					path: 'dashboard',
					children: [
						{
							path: 'home',
							element: <DashboardDefault />,
						},
						{
							path: 'coupons',
							element: <CreateCoupon />,
						},
						/*             {
              path: "analytics",
              element: <DashboardAnalytics />,
            }, */
					],
				},

				{
					path: 'coupons',
					children: [
						{
							path: 'create',
							element: <CreateCoupon />,
						},
					],
				},
				{
					path: 'posts',
					element: <Posts />,
				},
				{
					path: 'widget',
					children: [
						{
							path: 'statistics',
							element: <WidgetStatistics />,
						},
						{
							path: 'data',
							element: <WidgetData />,
						},
						{
							path: 'chart',
							element: <WidgetChart />,
						},
					],
				},
				{
					path: 'apps',
					children: [
						{
							path: 'chat',
							element: <AppChat />,
						},
						{
							path: 'calendar',
							element: <AppCalendar />,
						},
						{
							path: 'kanban',
							element: <AppKanban />,
							children: [
								{
									path: 'backlogs',
									element: <AppKanbanBacklogs />,
								},
								{
									path: 'board',
									element: <AppKanbanBoard />,
								},
							],
						},
						{
							path: 'customer',
							children: [
								{
									path: 'customer-list',
									element: <AppCustomerList />,
								},
								{
									path: 'customer-card',
									element: <AppCustomerCard />,
								},
							],
						},
						{
							path: 'invoice',
							children: [
								{
									path: 'dashboard',
									element: <AppInvoiceDashboard />,
								},
								{
									path: 'create',
									element: <AppInvoiceCreate />,
								},
								{
									path: 'details/:id',
									element: <AppInvoiceDetails />,
								},
								{
									path: 'edit/:id',
									element: <AppInvoiceEdit />,
								},
								{
									path: 'list',
									element: <AppInvoiceList />,
								},
							],
						},
						{
							path: 'profiles',
							children: [
								{
									path: 'account',
									element: <AccountProfile />,
									children: [
										{
											path: 'basic',
											element: <AccountTabProfile />,
										},
										{
											path: 'personal',
											element: <AccountTabPersonal />,
										},
										{
											path: 'my-account',
											element: <AccountTabAccount />,
										},
										{
											path: 'password',
											element: <AccountTabPassword />,
										},
										{
											path: 'role',
											element: <AccountTabRole />,
										},
										{
											path: 'settings',
											element: <AccountTabSettings />,
										},
									],
								},
								{
									path: 'user',
									element: <UserProfile />,
									children: [
										{
											path: 'personal',
											element: <UserTabPersonal />,
										},
										{
											path: 'payment',
											element: <UserTabPayment />,
										},
										{
											path: 'password',
											element: <UserTabPassword />,
										},
										{
											path: 'settings',
											element: <UserTabSettings />,
										},
									],
								},
							],
						},
						{
							path: 'e-commerce',
							children: [
								{
									path: 'products',
									element: <AppECommProducts />,
								},
								{
									path: 'product-details/:id',
									element: <AppECommProductDetails />,
								},
								{
									path: 'product-list',
									element: <AppECommProductList />,
								},
								{
									path: 'add-new-product',
									element: <AppECommAddProduct />,
								},
								{
									path: 'checkout',
									element: <AppECommCheckout />,
								},
							],
						},
					],
				},
				{
					path: 'forms',
					children: [
						{
							path: 'validation',
							element: <FormsValidation />,
						},
						{
							path: 'wizard',
							element: <FormsWizard />,
						},
						{
							path: 'layout',
							children: [
								{
									path: 'basic',
									element: <FormsLayoutBasic />,
								},
								{
									path: 'multi-column',
									element: <FormsLayoutMultiColumn />,
								},
								{
									path: 'action-bar',
									element: <FormsLayoutActionBar />,
								},
								{
									path: 'sticky-bar',
									element: <FormsLayoutStickyBar />,
								},
							],
						},
						{
							path: 'plugins',
							children: [
								{
									path: 'mask',
									element: <FormsPluginsMask />,
								},
								{
									path: 'clipboard',
									element: <FormsPluginsClipboard />,
								},
								{
									path: 're-captcha',
									element: <FormsPluginsRecaptcha />,
								},
								{
									path: 'editor',
									element: <FormsPluginsEditor />,
								},
								{
									path: 'dropzone',
									element: <FormsPluginsDropzone />,
								},
							],
						},
					],
				},
				{
					path: 'tables',
					children: [
						{
							path: 'react-table',
							children: [
								{
									path: 'basic',
									element: <ReactTableBasic />,
								},
								{
									path: 'sorting',
									element: <ReactTableSorting />,
								},
								{
									path: 'filtering',
									element: <ReactTableFiltering />,
								},
								{
									path: 'grouping',
									element: <ReactTableGrouping />,
								},
								{
									path: 'pagination',
									element: <ReactTablePagination />,
								},
								{
									path: 'row-selection',
									element: <ReactTableRowSelection />,
								},
								{
									path: 'expanding',
									element: <ReactTableExpanding />,
								},
								{
									path: 'editable',
									element: <ReactTableEditable />,
								},
								{
									path: 'drag-drop',
									element: <ReactTableDragDrop />,
								},
								{
									path: 'column-hiding',
									element: <ReactTableColumnHiding />,
								},
								{
									path: 'column-resizing',
									element: <ReactTableColumnResizing />,
								},
								{
									path: 'sticky-table',
									element: <ReactTableStickyTable />,
								},
								{
									path: 'umbrella',
									element: <ReactTableUmbrella />,
								},
								{
									path: 'empty',
									element: <ReactTableEmpty />,
								},
							],
						},
						{
							path: 'mui-table',
							children: [
								{
									path: 'basic',
									element: <MuiTableBasic />,
								},
								{
									path: 'dense',
									element: <MuiTableDense />,
								},
								{
									path: 'enhanced',
									element: <MuiTableEnhanced />,
								},
								{
									path: 'datatable',
									element: <MuiTableDatatable />,
								},
								{
									path: 'custom',
									element: <MuiTableCustom />,
								},
								{
									path: 'fixed-header',
									element: <MuiTableFixedHeader />,
								},
								{
									path: 'collapse',
									element: <MuiTableCollapse />,
								},
							],
						},
					],
				},
				{
					path: 'charts',
					children: [
						{
							path: 'apexchart',
							element: <ChartApexchart />,
						},
						{
							path: 'org-chart',
							element: <ChartOrganization />,
						},
					],
				},
				{
					path: 'sample-page',
					element: <SamplePage />,
				},
				{
					path: 'pricing',
					element: <PricingPage />,
				},
				{
					path: 'feedback',
					element: <AppCustomerFeedback />,
				},
				{
					path: 'fans',
					element: <AppCustomerFans />,
				},
				{
					path: 'messengers',
					element: <AppChatMessangers />,
				},
				{
					path: 'series',
					element: <Projects />,
					children: [
						{
							path: '/series',
							element: <Projects />,
						},
						{
							path: '/series/:id',
							element: <ProjectPage />,
						},
						{
							path: '/series/create',
							element: <ProjectPage />,
						},
					],
				},
			],
		},
		{
			path: '/maintenance',
			element: <CommonLayout />,
			children: [
				{
					path: '404',
					element: <MaintenanceError />,
				},
				{
					path: '500',
					element: <MaintenanceError500 />,
				},
				{
					path: 'under-construction',
					element: <MaintenanceUnderConstruction />,
				},
				{
					path: 'coming-soon',
					element: <MaintenanceComingSoon />,
				},
			],
		},
		{
			path: '/auth',
			element: <CommonLayout />,
			children: [
				{
					path: 'login',
					element: <AuthLogin />,
				},
				{
					path: 'register',
					element: <AuthRegister />,
				},
				{
					path: 'forgot-password',
					element: <AuthForgotPassword />,
				},
				{
					path: 'reset-password',
					element: <AuthResetPassword />,
				},
				{
					path: 'check-mail',
					element: <AuthCheckMail />,
				},
				{
					path: 'code-verification',
					element: <AuthCodeVerification />,
				},
			],
		},
	],
}

export default MainRoutes
