// third-party
import { createSlice } from "@reduxjs/toolkit";

// project-imports
import axios from "utils/axios";
import { dispatch } from "../index";

// types
import { Subscriptions } from "types/subscriptions";

const monthUsersCount = Array(12).fill(0);
const initialState: Subscriptions = {
  error: false,
  data: [],
  amount: 0,
  users: [0, 0],
  views: [],
  viewPer: 0,
  monthlyView: [],
  donateAmount: 0,
  totalAmount: 0,
  totalUsers: 0,
  totalViewers: 0,
  averagePurchase: 0,
  paid: 0,
  accountBalance: 0,
  projects: [],
  totalViews: 0,
  totalBookmarked: 0,
  bookmarkData: [],
  subscribers: monthUsersCount,
  viewers: monthUsersCount,
  bookmarkedUsers: monthUsersCount,
};

// ==============================|| SLICE - CHAT ||============================== //

const subscription = createSlice({
  name: "users",
  initialState,
  reducers: {
    // HAS ERROR
    reset: () => initialState,
    hasError(state, action) {
      state.error = action.payload;
    },

    // GET USER
    getUsersMonthlyCount(state, action) {
      state.users = action.payload;
    },
    setBaseCount(state) {
      state.bookmarkedUsers = monthUsersCount;
      state.viewers = monthUsersCount;
    },
    getCommonAmount(state, action) {
      state.amount = action.payload;
    },
    getData(state, action) {
      if (action?.payload?.data) {
        state.data = action.payload.data;
        state.amount = action.payload.amount;
        state.users = action.payload.data
          .sort((a: any, b: any) => a.month - b.month)
          .reduce((arg: number[], val: any) => {
            arg.push(val.userCount);
            return arg;
          }, []);

        state.totalUsers = (state.users as number[]).reduce(
          (arg: number, val: number) => {
            arg += val;
            return arg;
          },
          0
        );

        state.views = action.payload.viewData;

        state.monthlyView = action.payload.viewData
          .sort((a: any, b: any) => a._id - b._id)
          .reduce((arg: number[], val: any) => {
            arg.push(val.count);
            return arg;
          }, []);

        state.totalViewers = (state.monthlyView as number[]).reduce(
          (arg: number, val: number) => {
            arg += val;
            return arg;
          },
          0
        );

        state.totalViews = action.payload.viewData.reduce(
          (arg: number[], val: any) => {
            return (arg += val.count);
          },
          0
        );

        state.totalBookmarked = action.payload.bookmarkData.reduce(
          (arg: number[], val: any) => {
            return (arg += val.count);
          },
          0
        );

        action?.payload?.data.forEach((element: any) => {
          state.subscribers[element.month - 1] = element.userCount;
        });

        action?.payload?.viewData.forEach((element: any) => {
          state.viewers[element._id - 1] = element.count;
        });

        action?.payload?.bookmarkData.forEach((element: any) => {
          state.bookmarkedUsers[element._id - 1] = element.count;
        });

        state.donateAmount = action.payload.donateAmount;
        state.totalAmount = action.payload.totalAmount;
        state.averagePurchase = action.payload.averagePurchase;
        state.paid = action.payload.paid;
        state.accountBalance = action.payload.accountBalance;
        state.projects = action.payload.projects;
        state.bookmarkData = action.payload.bookmarkData;
      } else {
        state.data = [];
        state.amount = 0;
        state.users = [0, 0];
        state.views = [];
        state.monthlyView = [];
        state.donateAmount = 0;
        state.totalAmount = 0;
        state.totalUsers = 0;
        state.totalViewers = 0;
        state.averagePurchase = 0;
        state.paid = 0;
        state.accountBalance = 0;
        state.projects = [];
        state.totalViews = 0;
        state.bookmarkData = [];
        state.viewers = monthUsersCount;
        state.bookmarkedUsers = monthUsersCount;
        state.subscribers = monthUsersCount;
      }
    },
    getProjectData(state, action) {
      state.totalUsers = (state.users as number[]).reduce(
        (arg: number, val: number) => {
          arg += val;
          return arg;
        },
        0
      );
      state.totalViews = action?.payload?.viewData.reduce(
        (arg: number[], val: any) => {
          return (arg += val.count);
        },
        0
      );

      state.totalBookmarked = action.payload.bookmarkData.reduce(
        (arg: number[], val: any) => {
          return (arg += val.count);
        },
        0
      );

      state.bookmarkData = action.payload.bookmarkData;

      state.views = action.payload.viewData;

      state.monthlyView = action.payload?.viewData
        .sort((a: any, b: any) => a._id - b._id)
        .reduce((arg: number[], val: any) => {
          arg.push(val.count);
          return arg;
        }, []);

      state.totalViewers = (state.monthlyView as number[]).reduce(
        (arg: number, val: number) => {
          arg += val;
          return arg;
        },
        0
      );

      action?.payload?.viewData.forEach((element: any) => {
        state.viewers[element._id - 1] = element.count;
      });

      action?.payload?.bookmarkData.forEach((element: any) => {
        state.bookmarkedUsers[element._id - 1] = element.count;
      });
    },
  },
});

// Reducer
export default subscription.reducer;
export const {
  getCommonAmount,
  getUsersMonthlyCount,
  getData,
  getProjectData,
  reset,
  setBaseCount,
} = subscription.actions;

export function getUsersCount() {
  return async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_V1_ENDPOINT}/analytics?projectId=6400809b651f9fc369319f43`
      );

      dispatch(subscription.actions.getUsersMonthlyCount(response.data));
    } catch (error) {
      dispatch(subscription.actions.hasError(error));
    }
  };
}

export function getAmount() {
  return async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_V1_ENDPOINT}/analytics?projectId=6400809b651f9fc369319f43`
      );
      dispatch(subscription.actions.getCommonAmount(response.data));
    } catch (error) {
      dispatch(subscription.actions.hasError(error));
    }
  };
}
