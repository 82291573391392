import { useMemo } from 'react'

// material-ui
import { useTheme } from '@mui/material/styles'
import { Box, Drawer, useMediaQuery } from '@mui/material'

// project-imports
import DrawerHeader from './DrawerHeader'
import DrawerContent from './DrawerContent'
import MiniDrawerStyled from './MiniDrawerStyled'
import { DRAWER_WIDTH } from 'config'

// ==============================|| MAIN LAYOUT - DRAWER ||============================== //

interface Props {
	open: boolean
	window?: () => Window
	handleDrawerToggle?: () => void
}

const MainDrawer = ({ open, handleDrawerToggle, window }: Props) => {
	const theme = useTheme()
	const downLG = useMediaQuery(theme.breakpoints.down('lg'))

	// responsive drawer container
	const container =
		window !== undefined ? () => window().document.body : undefined

	// header content
	const drawerContent = useMemo(() => <DrawerContent />, [])
	const drawerHeader = useMemo(() => <DrawerHeader open={open} />, [open])

	return (
		<Box
			component='nav'
			sx={{ flexShrink: { md: 0 }, zIndex: 1200 }}
			aria-label='mailbox folders'
		>
			{!downLG ? (
				<MiniDrawerStyled variant='permanent' open={open}>
					{drawerHeader}
					{drawerContent}
				</MiniDrawerStyled>
			) : (
				<Drawer
					container={container}
					variant='temporary'
					open={open}
					onClose={handleDrawerToggle}
					ModalProps={{ keepMounted: true }}
					sx={{
						display: { xs: 'block', lg: 'none' },
						'&.MuiDrawer-paper': {
							boxSizing: 'border-box',
							width: DRAWER_WIDTH,
							borderRight: `1px solid ${theme.palette.divider}`,
							backgroundImage: 'none',
							boxShadow: 'inherit',
							background: 'red',
						},
					}}
				>
					{drawerHeader}
					{drawerContent}
				</Drawer>
			)}
		</Box>
	)
}

export default MainDrawer
