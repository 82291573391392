// third-party
import { FormattedMessage } from 'react-intl'

// assets
import { Sms, Message } from 'iconsax-react'

// type
import { NavItemType } from 'types/menu'

// icons
const icons = {
	email: Sms,
	chat: Message,
}

// ==============================|| MENU ITEMS - SUPPORT ||============================== //

const support: NavItemType = {
	id: 'other',
	title: <FormattedMessage id='support' />,
	type: 'group',
	children: [
		{
			id: 'email',
			title: <FormattedMessage id='email' />,
			type: 'item',
			url: 'mailto:max@toontube.co',
			icon: icons.email,
			external: true,
		},
		{
			id: 'chat',
			title: <FormattedMessage id='chat' />,
			type: 'item',
			url: 'https://wa.me/972546632161',
			icon: icons.chat,
			external: true,
			target: true,
		},
	],
}

export default support
