const DEFAULT_USER_IMAGE = `${process.env.REACT_APP_API_IMAGE}/image/favicon.ico`

const CURRENCY = '$'

const ROUTES = {
	subscribers: '/subscribers',
	subscribersAdd: '/subscribers/add',
	projects: '/series',
	projectAdd: '/series/create',
}

export { DEFAULT_USER_IMAGE, CURRENCY, ROUTES }
