// material-ui
import { useTheme } from "@mui/material/styles";

/**
 * if you want to use image instead of <svg> uncomment following.
 *
 * import logoIconDark from 'assets/images/logo-icon-dark.svg';
 * import logoIcon from 'assets/images/logo-icon.svg';
 *
 */

// ==============================|| LOGO ICON SVG ||============================== //

const LogoIcon = () => {
  const theme = useTheme();

  return (
    /**
     * if you want to use image instead of svg uncomment following, and comment out <svg> element.
     *
     * <img src={theme.palette.mode === 'dark' ? logoIconDark : logoIcon} alt="icon logo" width="100" />
     *
     */
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="64"
      height="38"
      viewBox="0 0 64 38"
      fill="none"
    >
      <g clipPath="url(#clip0_1101_2709)">
        <path
          d="M6.77921 17.3246H11.0514L14.8455 8.80486H19.0991L20.992 4.55396H8.21225L6.31921 8.80486H10.5733L6.77921 17.3246Z"
          fill={
            theme.palette.mode === "dark"
              ? "var(--white-color)"
              : "var(--black-color)"
          }
        ></path>
        <path
          d="M18.7775 10.9574C18.3549 11.9063 18.1395 12.7998 18.1311 13.6394C18.1223 14.4785 18.292 15.2112 18.6402 15.8374C18.9875 16.4643 19.5036 16.9597 20.1868 17.3246C20.8702 17.6893 21.6867 17.8719 22.6363 17.8719C23.5855 17.8719 24.5617 17.6893 25.5636 17.3246C26.5659 16.9597 27.5195 16.4643 28.426 15.8374C29.3315 15.2112 30.1537 14.4785 30.8927 13.6394C31.6319 12.7998 32.2122 11.9063 32.6345 10.9574C33.057 10.009 33.2742 9.11194 33.2853 8.26649C33.2964 7.42113 33.1281 6.68567 32.7804 6.05918C32.432 5.43269 31.9197 4.93706 31.2426 4.57197C30.5654 4.20728 29.7519 4.02478 28.8027 4.02478C27.8531 4.02478 26.8739 4.20728 25.8659 4.57189C24.8575 4.93698 23.9002 5.43261 22.9945 6.0591C22.0886 6.68559 21.2651 7.42105 20.5237 8.26641C19.7819 9.11194 19.1997 10.009 18.7775 10.9574ZM23.0313 10.9574C23.1992 10.5808 23.4261 10.2275 23.7126 9.89912C23.9988 9.57096 24.3189 9.2823 24.6735 9.03272C25.0283 8.78363 25.4007 8.58865 25.7916 8.449C26.1827 8.3091 26.5608 8.23907 26.9261 8.23907C27.291 8.23907 27.6035 8.3091 27.8637 8.449C28.1243 8.58865 28.3203 8.78363 28.4527 9.03272C28.5851 9.2823 28.6481 9.57096 28.6423 9.89912C28.6356 10.2275 28.5487 10.5808 28.381 10.9574C28.213 11.3348 27.9856 11.6871 27.6996 12.0156C27.4131 12.344 27.0945 12.6297 26.7423 12.8731C26.3907 13.1164 26.0226 13.3078 25.6376 13.4477C25.2527 13.5879 24.8778 13.6577 24.5129 13.6577C24.1476 13.6577 23.8318 13.5879 23.5654 13.4477C23.2991 13.3079 23.0984 13.1164 22.9632 12.8731C22.8282 12.6298 22.7637 12.344 22.7699 12.0156C22.7763 11.6871 22.8635 11.3347 23.0313 10.9574Z"
          fill={
            theme.palette.mode === "dark"
              ? "var(--white-color)"
              : "var(--black-color)"
          }
        ></path>
        <path
          d="M33.1727 15.8374C33.5203 16.4643 34.0355 16.9597 34.7193 17.3246C35.4029 17.6893 36.2195 17.8719 37.1685 17.8719C38.118 17.8719 39.0938 17.6893 40.0962 17.3246C41.0985 16.9597 42.0523 16.4643 42.9585 15.8374C43.8641 15.2112 44.6863 14.4785 45.4254 13.6394C46.1639 12.7998 46.7447 11.9063 47.1674 10.9574C47.5895 10.009 47.8063 9.11194 47.818 8.26649C47.8291 7.42113 47.6608 6.68567 47.313 6.05918C46.9648 5.43269 46.4523 4.93706 45.7751 4.57197C45.0975 4.20728 44.2844 4.02478 43.3351 4.02478C42.3859 4.02478 41.4067 4.20728 40.3982 4.57189C39.3896 4.93698 38.4329 5.43261 37.5272 6.0591C36.6208 6.68559 35.7975 7.42105 35.0561 8.26641C34.3145 9.11186 33.732 10.0089 33.3099 10.9573C32.8872 11.9063 32.672 12.7998 32.6635 13.6393C32.6549 14.4785 32.8241 15.2112 33.1727 15.8374ZM37.5639 10.9574C37.7316 10.5808 37.9588 10.2275 38.2452 9.89912C38.531 9.57096 38.8515 9.2823 39.206 9.03272C39.5604 8.78363 39.9335 8.58865 40.3243 8.449C40.7153 8.3091 41.0934 8.23907 41.4584 8.23907C41.8235 8.23907 42.1363 8.3091 42.3964 8.449C42.6569 8.58865 42.853 8.78363 42.9853 9.03272C43.1178 9.2823 43.1807 9.57096 43.1745 9.89912C43.1683 10.2275 43.0811 10.5808 42.9134 10.9574C42.7452 11.3348 42.5183 11.6871 42.2318 12.0156C41.9459 12.344 41.6269 12.6297 41.2751 12.8731C40.9234 13.1164 40.5552 13.3078 40.1702 13.4477C39.7853 13.5879 39.4103 13.6577 39.0451 13.6577C38.6801 13.6577 38.3643 13.5879 38.0981 13.4477C37.8319 13.3079 37.6306 13.1164 37.4958 12.8731C37.3607 12.6298 37.2961 12.344 37.3027 12.0156C37.3088 11.6871 37.396 11.3347 37.5639 10.9574Z"
          fill={
            theme.palette.mode === "dark"
              ? "var(--white-color)"
              : "var(--black-color)"
          }
        ></path>
        <path
          d="M59.746 4.55396L57.0486 10.6109L55.4738 4.55396H51.2203L45.5332 17.3246H49.7867L52.4841 11.2677L54.059 17.3246H58.3129L64 4.55396H59.746Z"
          fill={
            theme.palette.mode === "dark"
              ? "var(--white-color)"
              : "var(--black-color)"
          }
        ></path>
        <path
          d="M28.5384 18.9259H24.2843L21.189 25.877C21.059 26.1687 20.8813 26.4514 20.6562 26.7252C20.4308 26.9989 20.1807 27.2392 19.9061 27.4458C19.6315 27.6528 19.3363 27.8169 19.0206 27.9381C18.7042 28.0603 18.3824 28.1207 18.0537 28.1207C17.737 28.1207 17.4748 28.0603 17.2679 27.9381C17.0599 27.8169 16.911 27.6528 16.821 27.4458C16.7303 27.2392 16.6939 26.9989 16.7125 26.7252C16.7308 26.4513 16.8051 26.1687 16.935 25.877L20.0305 18.9259H15.7768L12.6814 25.877C12.2914 26.7525 12.0898 27.5798 12.0786 28.3579C12.0664 29.1365 12.219 29.8117 12.5366 30.3831C12.8541 30.9547 13.328 31.4075 13.9581 31.7424C14.5882 32.0767 15.3414 32.2437 16.2178 32.2437C17.094 32.2437 17.9963 32.0766 18.9238 31.7424C19.852 31.4075 20.7322 30.9547 21.565 30.3831C22.3975 29.8117 23.1547 29.1365 23.8362 28.3579C24.5173 27.5798 25.0531 26.7525 25.4431 25.877L28.5384 18.9259Z"
          fill="#FE2C55"
        ></path>
        <path
          d="M39.9572 26.4514C39.9528 25.9957 39.8577 25.6215 39.6713 25.3298C40.1175 25.0378 40.538 24.6604 40.933 24.1984C41.3275 23.736 41.6436 23.2377 41.882 22.7024C42.1039 22.2039 42.2279 21.7265 42.255 21.2703C42.2819 20.8144 42.2012 20.4133 42.0151 20.0662C41.8289 19.7196 41.5353 19.4427 41.1343 19.2363C40.7335 19.0293 40.2227 18.9259 39.6019 18.9259H29.7612L24.0741 31.6965H33.9147C34.5357 31.6965 35.1416 31.593 35.7329 31.3864C36.3237 31.1794 36.8687 30.9058 37.367 30.5656C37.8655 30.2247 38.3063 29.8294 38.6895 29.3795C39.0726 28.9295 39.3749 28.4553 39.5971 27.9568C39.8408 27.4091 39.9605 26.9077 39.9572 26.4514ZM35.4126 27.7195C35.3152 27.9381 35.1532 28.1234 34.9273 28.2761C34.7015 28.4277 34.4789 28.504 34.2596 28.504H29.7867L30.4935 26.9167H34.9665C35.1857 26.9167 35.3406 26.9924 35.4312 27.1446C35.5215 27.2967 35.5155 27.4881 35.4126 27.7195ZM37.5411 22.9395C37.4435 23.1585 37.2816 23.3439 37.0559 23.4958C36.8298 23.648 36.6075 23.7239 36.3883 23.7239H31.9154L32.6303 22.1187H37.1031C37.3224 22.1187 37.4759 22.1975 37.5637 22.3559C37.6515 22.5137 37.6439 22.7085 37.5411 22.9395Z"
          fill="var(--red-color)"
        ></path>
        <path
          d="M39.1329 31.6965H51.9127L53.3344 28.5039H44.8082L45.523 26.8985H52.9903L54.4122 23.7058H46.945L47.6518 22.1186H56.178L57.5998 18.9259H44.82L39.1329 31.6965Z"
          fill="var(--red-color)"
        ></path>
        <path
          d="M8.47272 23.1591H12.709L14.5918 18.9259H1.8828L0 23.1591H4.23632L0.47072 31.6253H4.70704L1.8828 37.9751L10.8262 27.3922H6.58984L8.47272 23.1591Z"
          fill="var(--red-color)"
        ></path>
      </g>
      <defs>
        <clipPath id="clip0_1101_2709">
          <rect
            width="64"
            height="37.9503"
            fill="currentColor"
            transform="translate(0 0.0247803)"
          ></rect>
        </clipPath>
      </defs>
    </svg>
  );
};

export default LogoIcon;
