import { useEffect, useState } from 'react'

// material-ui
import { useTheme } from '@mui/material/styles'
import { Stack, Typography, useMediaQuery } from '@mui/material'

// project-imports
import NavGroup from './NavGroup'
import menuItem from 'menu-items'

import { useSelector } from 'store'
import useConfig from 'hooks/useConfig'
import { HORIZONTAL_MAX_ITEM } from 'config'

// types
import { NavItemType } from 'types/menu'
import { LAYOUT_CONST } from 'types/config'

// project-imports

// type
import { Home3, HomeTrendUp, Box1 } from 'iconsax-react'
import { FormattedMessage } from 'react-intl'
import ControlButtons from './controlButtons'

const icons = {
	navigation: Home3,
	dashboard: HomeTrendUp,
	components: Box1,
}

// ==============================|| DRAWER CONTENT - NAVIGATION ||============================== //

const Navigation = () => {
	const theme = useTheme()

	const downLG = useMediaQuery(theme.breakpoints.down('lg'))
	const { menuDashboard } = useSelector(state => state.menu)

	const { menuOrientation } = useConfig()
	const { drawerOpen } = useSelector(state => state.menu)

	const [selectedItems, setSelectedItems] = useState<string | undefined>('')
	const [selectedLevel, setSelectedLevel] = useState<number>(0)
	const [menuItems, setMenuItems] = useState<{ items: NavItemType[] }>(menuItem)

	useEffect(() => {
		handlerMenuItem()
		// eslint-disable-next-line
	}, [menuDashboard])

	const SubChildrenLis = (SubChildrenLis: NavItemType[]) => {
		return SubChildrenLis?.map((subList: NavItemType) => {
			return {
				...subList,
				title: <FormattedMessage id={`${subList.title}`} />,
				// @ts-ignore
				icon: icons[subList.icon],
			}
		})
	}

	const menuList = (subList: NavItemType) => {
		let list: NavItemType = {
			...subList,
			title: <FormattedMessage id={`${subList.title}`} />,
			// @ts-ignore
			icon: icons[subList.icon],
		}

		if (subList.type === 'collapse') {
			list.children = SubChildrenLis(subList.children!)
		}
		return list
	}
	const ChildrenList: NavItemType[] | undefined = menuDashboard?.children?.map(
		(subList: NavItemType) => {
			return menuList(subList)
		}
	)

	let getDash: NavItemType = {
		...menuDashboard,
		title: <FormattedMessage id={`${menuDashboard.title}`} />,
		// @ts-ignore
		icon: icons[menuDashboard.icon],
		children: ChildrenList,
	}

	const handlerMenuItem = () => {
		const isFound = menuItem.items.some(
			element => element.id === 'group-dashboard'
		)
		if (getDash?.id !== undefined && !isFound) {
			menuItem.items.splice(0, 0, getDash)
			setMenuItems(menuItem)
		} else if (isFound) {
			const newMenuItem = { ...menuItem }
			newMenuItem.items[0] = getDash
			setMenuItems(newMenuItem)
		}
	}

	const isHorizontal =
		menuOrientation === LAYOUT_CONST.HORIZONTAL_LAYOUT && !downLG

	const lastItem = isHorizontal ? HORIZONTAL_MAX_ITEM : null
	let lastItemIndex = menuItems.items.length - 1
	let remItems: NavItemType[] = []
	let lastItemId: string

	if (lastItem && lastItem < menuItems.items.length) {
		lastItemId = menuItems.items[lastItem - 1].id!
		lastItemIndex = lastItem - 1
		remItems = menuItems.items
			.slice(lastItem - 1, menuItems.items.length)
			.map(item => ({
				title: item.title,
				elements: item.children,
				icon: item.icon,
			}))
	}

	const navGroups = menuItems.items.slice(0, lastItemIndex + 1).map(item => {
		switch (item.type) {
			case 'group':
				return (
					<NavGroup
						key={item.id}
						setSelectedItems={setSelectedItems}
						setSelectedLevel={setSelectedLevel}
						selectedLevel={selectedLevel}
						selectedItems={selectedItems}
						lastItem={lastItem!}
						remItems={remItems}
						lastItemId={lastItemId}
						item={item}
					/>
				)
			default:
				return (
					<Typography key={item.id} variant='h6' color='error' align='center'>
						Fix - Navigation Group
					</Typography>
				)
		}
	})
	return (
		<Stack
			sx={{
				pt: drawerOpen ? (isHorizontal ? 0 : 2) : 0,
				'& > ul:first-of-type': { mt: 0 },
				display: isHorizontal ? { xs: 'block', lg: 'flex' } : 'block',
			}}
			spacing={5}
		>
			{navGroups}
			<ControlButtons />
		</Stack>
	)
}

export default Navigation
